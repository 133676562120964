const theme = {
  colors: {
    text: '#000',
    background: '#FFF',
    primary: '#000080',
    accent: '#fff',
    muted: 'rgba(0, 0, 0, 0.7)',
    cardBg: '#fff',
    borderColor:"#540229",
    labelText: "#777",
    inputBorder: "#aaa",
    inputBackground: "#fff",
    socialIcons:"#a8959e",
    socialIconsHover:"#5c2941",
    modes: {
      dark: {
        text: '#000',
        background: '#FFF',
        primary: '#000080',
        accent: '#fff',
        muted: 'rgba(0, 0, 0, 0.7)',
        buttonAccent: '#add8e6',
        cardBg: '#fff',
        borderColor:"#540229",
        labelText: "#777",
        inputBorder: "#aaa",
        inputBackground: "#fff",
        socialIcons:"#a8959e",
        socialIconsHover:"#5c2941",
      },
    }
  },
  links: {
    postLink: {
      color: 'muted',
      '&:hover': {
        color: 'text'
      }
    },
    button: {
      bg: 'buttonAccent',
      color: 'accent'
    }
  }
}

export default theme