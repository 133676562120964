/**
 * Implement Gatsby's Browser APIs in this file.
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Add support for loading static assets directly for local development
exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer')
    console.log('IntersectionObserver polyfilled')
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement('img')
  if (
    typeof testImg.style.objectFit !== 'string' ||
    typeof testImg.style.objectPosition !== 'string'
  ) {
    import('object-fit-images')
      .then(({ default: objectFitImages }) => {
        objectFitImages()
        console.log('Object-fit polyfilled')
      })
      .catch(error => {
        console.error('Failed to load object-fit-images polyfill:', error)
      })
  }
}

// Force image reload on route changes
exports.shouldUpdateScroll = () => {
  window.setTimeout(() => {
    const images = document.querySelectorAll('.gatsby-image-wrapper img')
    if (images?.length) {
      for (const img of images) {
        if (img.complete) {
          // For browsers that don't trigger onload for cached images
          const event = new Event('load')
          img.dispatchEvent(event)
        }
      }
    }
  }, 0)
  return true
}

exports.onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    'This application has been updated. ' +
      'Reload to display the latest version?'
  )

  if (answer === true) {
    window.location.reload()
  }
}