exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guides-employee-engagement-js": () => import("./../../../src/pages/guides/employee-engagement.js" /* webpackChunkName: "component---src-pages-guides-employee-engagement-js" */),
  "component---src-pages-guides-employee-onboarding-js": () => import("./../../../src/pages/guides/employee-onboarding.js" /* webpackChunkName: "component---src-pages-guides-employee-onboarding-js" */),
  "component---src-pages-guides-hr-compliance-js": () => import("./../../../src/pages/guides/hr-compliance.js" /* webpackChunkName: "component---src-pages-guides-hr-compliance-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-guides-performance-reviews-js": () => import("./../../../src/pages/guides/performance-reviews.js" /* webpackChunkName: "component---src-pages-guides-performance-reviews-js" */),
  "component---src-pages-guides-recruitment-hiring-js": () => import("./../../../src/pages/guides/recruitment-hiring.js" /* webpackChunkName: "component---src-pages-guides-recruitment-hiring-js" */),
  "component---src-pages-guides-remote-work-js": () => import("./../../../src/pages/guides/remote-work.js" /* webpackChunkName: "component---src-pages-guides-remote-work-js" */),
  "component---src-pages-policies-diversity-inclusion-js": () => import("./../../../src/pages/policies/diversity-inclusion.js" /* webpackChunkName: "component---src-pages-policies-diversity-inclusion-js" */),
  "component---src-pages-policies-employee-handbook-js": () => import("./../../../src/pages/policies/employee-handbook.js" /* webpackChunkName: "component---src-pages-policies-employee-handbook-js" */),
  "component---src-pages-policies-family-leave-js": () => import("./../../../src/pages/policies/family-leave.js" /* webpackChunkName: "component---src-pages-policies-family-leave-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-policies-leave-policy-js": () => import("./../../../src/pages/policies/leave-policy.js" /* webpackChunkName: "component---src-pages-policies-leave-policy-js" */),
  "component---src-pages-policies-performance-management-js": () => import("./../../../src/pages/policies/performance-management.js" /* webpackChunkName: "component---src-pages-policies-performance-management-js" */),
  "component---src-pages-policies-workplace-safety-js": () => import("./../../../src/pages/policies/workplace-safety.js" /* webpackChunkName: "component---src-pages-policies-workplace-safety-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-compliance-checklist-js": () => import("./../../../src/pages/resources/compliance-checklist.js" /* webpackChunkName: "component---src-pages-resources-compliance-checklist-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-resources-templates-360-feedback-template-download-js": () => import("./../../../src/pages/resources/templates/360-feedback-template-download.js" /* webpackChunkName: "component---src-pages-resources-templates-360-feedback-template-download-js" */),
  "component---src-pages-resources-templates-candidate-evaluation-scorecard-download-js": () => import("./../../../src/pages/resources/templates/candidate-evaluation-scorecard-download.js" /* webpackChunkName: "component---src-pages-resources-templates-candidate-evaluation-scorecard-download-js" */),
  "component---src-pages-resources-templates-engagement-action-plan-download-js": () => import("./../../../src/pages/resources/templates/engagement-action-plan-download.js" /* webpackChunkName: "component---src-pages-resources-templates-engagement-action-plan-download-js" */),
  "component---src-pages-resources-templates-engagement-survey-template-download-js": () => import("./../../../src/pages/resources/templates/engagement-survey-template-download.js" /* webpackChunkName: "component---src-pages-resources-templates-engagement-survey-template-download-js" */),
  "component---src-pages-resources-templates-interview-question-bank-download-js": () => import("./../../../src/pages/resources/templates/interview-question-bank-download.js" /* webpackChunkName: "component---src-pages-resources-templates-interview-question-bank-download-js" */),
  "component---src-pages-resources-templates-onboarding-checklist-template-js": () => import("./../../../src/pages/resources/templates/onboarding-checklist-template.js" /* webpackChunkName: "component---src-pages-resources-templates-onboarding-checklist-template-js" */),
  "component---src-pages-resources-templates-onboarding-survey-template-js": () => import("./../../../src/pages/resources/templates/onboarding-survey-template.js" /* webpackChunkName: "component---src-pages-resources-templates-onboarding-survey-template-js" */),
  "component---src-pages-resources-templates-organizational-health-assessment-download-js": () => import("./../../../src/pages/resources/templates/organizational-health-assessment-download.js" /* webpackChunkName: "component---src-pages-resources-templates-organizational-health-assessment-download-js" */),
  "component---src-pages-resources-templates-pulse-survey-template-download-js": () => import("./../../../src/pages/resources/templates/pulse-survey-template-download.js" /* webpackChunkName: "component---src-pages-resources-templates-pulse-survey-template-download-js" */),
  "component---src-pages-resources-templates-recognition-program-framework-download-js": () => import("./../../../src/pages/resources/templates/recognition-program-framework-download.js" /* webpackChunkName: "component---src-pages-resources-templates-recognition-program-framework-download-js" */),
  "component---src-pages-resources-templates-self-assessment-template-download-js": () => import("./../../../src/pages/resources/templates/self-assessment-template-download.js" /* webpackChunkName: "component---src-pages-resources-templates-self-assessment-template-download-js" */),
  "component---src-pages-resources-templates-standard-review-template-download-js": () => import("./../../../src/pages/resources/templates/standard-review-template-download.js" /* webpackChunkName: "component---src-pages-resources-templates-standard-review-template-download-js" */),
  "component---src-pages-resources-templates-training-plan-template-js": () => import("./../../../src/pages/resources/templates/training-plan-template.js" /* webpackChunkName: "component---src-pages-resources-templates-training-plan-template-js" */),
  "component---src-pages-resources-templates-welcome-kit-template-js": () => import("./../../../src/pages/resources/templates/welcome-kit-template.js" /* webpackChunkName: "component---src-pages-resources-templates-welcome-kit-template-js" */),
  "component---src-pages-services-custom-policy-development-js": () => import("./../../../src/pages/services/custom-policy-development.js" /* webpackChunkName: "component---src-pages-services-custom-policy-development-js" */),
  "component---src-pages-services-hr-software-selection-js": () => import("./../../../src/pages/services/hr-software-selection.js" /* webpackChunkName: "component---src-pages-services-hr-software-selection-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-software-applicant-tracking-system-js": () => import("./../../../src/pages/software/applicant-tracking-system.js" /* webpackChunkName: "component---src-pages-software-applicant-tracking-system-js" */),
  "component---src-pages-software-employee-engagement-js": () => import("./../../../src/pages/software/employee-engagement.js" /* webpackChunkName: "component---src-pages-software-employee-engagement-js" */),
  "component---src-pages-software-hris-js": () => import("./../../../src/pages/software/hris.js" /* webpackChunkName: "component---src-pages-software-hris-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-software-learning-management-system-js": () => import("./../../../src/pages/software/learning-management-system.js" /* webpackChunkName: "component---src-pages-software-learning-management-system-js" */),
  "component---src-pages-software-payroll-providers-js": () => import("./../../../src/pages/software/payroll-providers.js" /* webpackChunkName: "component---src-pages-software-payroll-providers-js" */),
  "component---src-pages-software-performance-management-js": () => import("./../../../src/pages/software/performance-management.js" /* webpackChunkName: "component---src-pages-software-performance-management-js" */),
  "component---src-pages-software-time-attendance-js": () => import("./../../../src/pages/software/time-attendance.js" /* webpackChunkName: "component---src-pages-software-time-attendance-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-tools-benefits-roi-simulator-js": () => import("./../../../src/pages/tools/benefits-roi-simulator.js" /* webpackChunkName: "component---src-pages-tools-benefits-roi-simulator-js" */),
  "component---src-pages-tools-compliance-risk-assessment-js": () => import("./../../../src/pages/tools/compliance-risk-assessment.js" /* webpackChunkName: "component---src-pages-tools-compliance-risk-assessment-js" */),
  "component---src-pages-tools-development-plan-template-js": () => import("./../../../src/pages/tools/development-plan-template.js" /* webpackChunkName: "component---src-pages-tools-development-plan-template-js" */),
  "component---src-pages-tools-diversity-inclusion-tracker-js": () => import("./../../../src/pages/tools/diversity-inclusion-tracker.js" /* webpackChunkName: "component---src-pages-tools-diversity-inclusion-tracker-js" */),
  "component---src-pages-tools-engagement-assessment-js": () => import("./../../../src/pages/tools/engagement-assessment.js" /* webpackChunkName: "component---src-pages-tools-engagement-assessment-js" */),
  "component---src-pages-tools-feedback-templates-js": () => import("./../../../src/pages/tools/feedback-templates.js" /* webpackChunkName: "component---src-pages-tools-feedback-templates-js" */),
  "component---src-pages-tools-goal-setting-template-js": () => import("./../../../src/pages/tools/goal-setting-template.js" /* webpackChunkName: "component---src-pages-tools-goal-setting-template-js" */),
  "component---src-pages-tools-hr-cost-calculator-js": () => import("./../../../src/pages/tools/hr-cost-calculator.js" /* webpackChunkName: "component---src-pages-tools-hr-cost-calculator-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-tools-performance-review-builder-js": () => import("./../../../src/pages/tools/performance-review-builder.js" /* webpackChunkName: "component---src-pages-tools-performance-review-builder-js" */),
  "component---src-pages-tools-salary-benchmarking-js": () => import("./../../../src/pages/tools/salary-benchmarking.js" /* webpackChunkName: "component---src-pages-tools-salary-benchmarking-js" */),
  "component---src-pages-tools-time-to-hire-calculator-js": () => import("./../../../src/pages/tools/time-to-hire-calculator.js" /* webpackChunkName: "component---src-pages-tools-time-to-hire-calculator-js" */),
  "component---src-pages-tools-turnover-cost-calculator-js": () => import("./../../../src/pages/tools/turnover-cost-calculator.js" /* webpackChunkName: "component---src-pages-tools-turnover-cost-calculator-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */)
}

